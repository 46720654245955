<template>
    <article class="media mt-0 mb-0 p-3">
        <figure class="media-left">
            <p class="image is-64x64">
                <img v-bind:alt="title.en"
                     v-bind:src="image">
            </p>
        </figure>
        <div class="media-content">
            <div class="content">
                <h1 class="title has-text-weight-bold is-5">
                    {{ title.en }}
                </h1>
                <h2 class="subtitle has-text-weight-normal is-6">
                    {{ title["zh-hk"] }}
                </h2>
            </div>
        </div>
    </article>
</template>

<script>
export default {
    name: "CouponQuickViewItem",
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {

        };
    },
    computed: {
        image () {
            return this.item?.images?.[0] ?
                `${ this.$config.apiUrl }/${ this.$config.apiVersion }/shop/product/image/${ this.item.images[0] }` :
                "";
        },
        title () {
            return this.item?.title ?? { en: "", "zh-hk": "", };
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
