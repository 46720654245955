<template>
    <section class="coupon-quick-view">
        <div class="columns">
            <div class="column is-4">
                <coupon-quick-view-summary class="mb-3"
                                           v-bind:coupon="coupon">
                </coupon-quick-view-summary>
                <coupon-quick-view-requirements class=""
                                                v-bind:coupon="coupon">
                </coupon-quick-view-requirements>
            </div>

            <div class="column is-8">
                <coupon-quick-view-applies-to class="mb-3"
                                              v-bind:coupon="coupon">
                </coupon-quick-view-applies-to>
            </div>
        </div>
    </section>
</template>

<script>
import CouponQuickViewSummary from "./CouponQuickView/Summary";
import CouponQuickViewAppliesTo from "./CouponQuickView/AppliesTo";
import CouponQuickViewRequirements from "./CouponQuickView/Requirements";

export default {
    name: "CouponQuickView",
    components: {
        CouponQuickViewRequirements,
        CouponQuickViewAppliesTo,
        CouponQuickViewSummary,
    },
    props: {
        coupon: {
            type: Object,
            required: true
        },
    },
    data () {
        return {

        };
    },
    computed: {

    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.coupon-quick-view{

}
</style>
