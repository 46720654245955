<template>
    <b-table detailed
             detail-key="_id"
             v-bind:data="coupons">
        <template>
            <b-table-column field="code"
                            label="Code"
                            v-slot="props">
                {{ props.row.code }}
            </b-table-column>

            <b-table-column field="type"
                            label="Type"
                            v-slot="props">
                {{ typeMap.get(props.row.type) }}
            </b-table-column>

            <b-table-column field="timesUsed"
                            label="Applied"
                            numeric
                            sortable
                            v-slot="props">
                {{ props.row.timesUsed }}
            </b-table-column>

            <b-table-column field="timesUsed"
                            label="Paid"
                            numeric
                            v-slot="props">
                {{ getOrdersByCouponId(props.row._id).length }}
            </b-table-column>

            <b-table-column field="startDate"
                            label="Start Date"
                            sortable
                            v-slot="props">
                {{ format(new Date(props.row.startDate), "PPp") }}
            </b-table-column>

            <b-table-column field="endDate"
                            label="End Date"
                            sortable
                            v-slot="props">
                {{ props.row.endDate ? format(new Date(props.row.endDate), "PPp") : "NA" }}
            </b-table-column>

            <b-table-column v-slot="props">
                <router-link class="button is-small is-warning mr-2"
                             v-bind:class="{ 'is-loading': isLoading }"
                             v-bind:to="`/shop/coupons/${ props.row._id }`">
                    <span class="icon is-small">
                        <font-awesome-icon icon="cog"></font-awesome-icon>
                    </span>
                </router-link>
                <button class="button is-small is-danger"
                        v-bind:class="{ 'is-loading': isLoading }"
                        v-on:click="deleteCoupon(props.row._id)">
                <span class="icon is-small">
                    <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                </span>
                </button>
            </b-table-column>
        </template>
        <template v-slot:detail="{ row }">
            <coupon-quick-view v-bind:coupon="row"></coupon-quick-view>
        </template>
    </b-table>
</template>

<script>
import format from "date-fns/format";

const typeMap = new Map(
    [
        ["percentage", "Percentage"],
        ["fixed-amount", "Fixed Amount"],
        ["buy-x-get-y", "Buy X Get Y"],
    ]
);

import CouponQuickView from "./CouponTable/CouponQuickView";

export default {
    name: "CouponTable",
    components: {
        CouponQuickView,
    },
    props: {
        coupons: {
            type: Array,
        },
    },
    data () {
        return {
            format,
            typeMap,

            isLoading: false,
        };
    },
    computed: {
        orders () {
            return this.$store.getters["shop/orders"];
        },
        ordersWithCoupon () {
            return this.orders.filter(
                ({ coupons }) => (coupons?.length ?? 0) > 0
            );
        },
        ordersPaid () {
            return this.ordersWithCoupon.filter(
                ({ status }) => status !== "pending"
            );
        },
    },
    methods: {
        getOrdersByCouponId (couponId) {
            return this.ordersPaid.filter(
                ({ coupons }) => coupons
                    .map(
                        ({ _id }) => _id
                    )
                    .includes(
                        couponId
                    )
            );
        },

        async deleteCoupon (couponId) {
            this.isLoading = true;
            try {
                await this.$store.dispatch("coupon/deleteCoupon", couponId);
            } catch (e) {
                console.error(e);
                this.isLoading = false;
                // TODO: show error toast
                return;
            }
            this.isLoading = false;
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
