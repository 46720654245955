<template>
    <section class="coupons">
        <tool-bar>
            Coupons
            <template slot="buttons">
                <div class="level-item">
                    <button class="button"
                            v-bind:class="{ 'is-loading': isLoading }"
                            v-on:click="refreshCoupons">
                        <span class="icon is-small">
                            <font-awesome-icon icon="redo-alt"></font-awesome-icon>
                        </span>
                        <span>Refresh</span>
                    </button>
                </div>
                <div class="level-item">
                    <router-link class="button has-text-black"
                                 v-bind:to="`/shop/coupons/new`">
                        <span class="icon is-small">
                            <font-awesome-icon icon="plus"></font-awesome-icon>
                        </span>
                        <span>Add a coupon</span>
                    </router-link>
                </div>
            </template>
        </tool-bar>
        <div class="coupon-table mb-5">
            <coupon-table v-bind:coupons="coupons"></coupon-table>
        </div>
    </section>
</template>

<script>
import ToolBar from "../layouts/ToolBar";
import CouponTable from "./Coupons/CouponTable";

export default {
    name: "Coupons",
    components: {
        CouponTable,
        ToolBar,
    },
    data () {
        return {
            isLoading: false,
        };
    },
    computed: {
        coupons () {
            return this.$store.getters["coupon/coupons"];
        },
    },
    methods: {
        async refreshCoupons () {
            this.isLoading = true;
            try {
                await this.$store
                    .dispatch(
                        "coupon/loadCoupon"
                    );
            } catch (e) {
                this.isLoading = false;
                return;
            }
            this.isLoading = false;
        },
    },
}
</script>

<style lang="scss" scoped>
.coupon-table {

}
</style>
