<template>
    <div class="card">
        <header class="card-header">
            <p class="card-header-title">
                Requirements
            </p>
        </header>
        <div class="card-content py-4">
            <ul>
                <li>
                    - {{ minRequirement }}
                </li>
                <li v-if="maxUsePerOrder">
                    - {{ maxUsePerOrder }}
                </li>
                <li v-if="maxTotalTimes">
                    - {{ maxTotalTimes }}
                </li>
                <li v-if="isLimitedToOnePerUser">
                    - {{ isLimitedToOnePerUser }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "CouponQuickViewRequirements",
    props: {
        coupon: {
            type: Object,
            required: true
        },
    },
    data () {
        return {

        };
    },
    computed: {
        minRequirement () {
            return this.coupon.hasMinAmount === true ?
                `Minimum purchase of HK$${ this.coupon.minAmount } worth of items` :
                this.coupon.hasMinQuantity === true ?
                    `Minimum purchase of ${ this.coupon.minQuantity } items` :
                    `No minimum requirement`;
        },
        maxUsePerOrder () {
            return this.coupon.maxUsePerOrder ?
                `Apply ${ this.coupon.maxUsePerOrder } time${ this.coupon.maxUsePerOrder > 1 ? "(s)" : "" } to an order` :
                "";
        },
        maxTotalTimes () {
            return this.coupon.maxTotalTimes ?
                `Coupon can be applied to ${ maxTotalTimes } orders` :
                "";
        },
        isLimitedToOnePerUser () {
            return this.coupon.isLimitedToOnePerUser === true ?
                "Each user can apply once only" :
                "";
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
