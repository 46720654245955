<template>
    <div class="card">
        <header class="card-header">
            <p class="card-header-title">
                Applies to
            </p>
        </header>
        <div class="card-content p-0">
            <h1 class="has-text-weight-semibold is-size-6 has-background-light px-4 py-1"
                v-if="coupon.type === 'buy-x-get-y'">
                Buys
            </h1>
            <h3 class="has-text-centered has-text-weight-bold is-size-4 p-3"
                 v-if="!coupon.customerBuys || coupon.customerBuys.length === 0">
                Any Product
            </h3>
            <coupon-quick-view-item v-for="item in coupon.customerBuys"
                                    v-bind:key="item._id"
                                    v-bind:item="item">
            </coupon-quick-view-item>

            <template v-if="coupon.type === 'buy-x-get-y'">
                <h1 class="has-text-weight-semibold is-size-6 has-background-light px-4 py-1">
                    Gets
                </h1>
                <div class="has-text-centered"
                     v-if="!coupon.customerGets || coupon.customerGets.length === 0">
                    Any product
                </div>
                <coupon-quick-view-item v-for="item in coupon.customerGets"
                                        v-bind:key="item._id"
                                        v-bind:item="item">
                </coupon-quick-view-item>
            </template>
        </div>
    </div>
</template>

<script>
import CouponQuickViewItem from "./Item";
export default {
    name: "CouponQuickViewAppliesTo",
    components: {CouponQuickViewItem},
    props: {
        coupon: {
            type: Object,
            required: true
        },
    },
    data () {
        return {

        };
    },
    computed: {

    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
