<template>
    <div class="card">
        <div class="card-content">
            <div class="mb-4">
                <h1 class="title has-text-weight-bold">
                    {{ coupon.code }}
                </h1>
                <h2 class="subtitle">
                    {{ coupon.description }}
                </h2>
            </div>
            <div class="">
                <div class="has-text-weight-semibold is-size-6">
                    Type
                </div>
                <div class="has-text-weight-bold is-size-4">
                    {{ typeMap.get(coupon.type) }}
                </div>
                <div class="is-size-6"
                     v-if="coupon.type === 'percentage'">
                    {{ coupon.discountRate }}% OFF
                </div>
                <div class="is-size-6"
                     v-if="coupon.type === 'fixed-amount'">
                    HK${{ coupon.discountAmount }} OFF
                </div>
                <div class="is-size-6"
                     v-if="coupon.type === 'buy-x-get-y'">
                    BUY {{ coupon.getQuantity }} GET {{ coupon.getDiscount }}% OFF for {{ coupon.buyQuantity }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const typeMap = new Map(
    [
        ["percentage", "Percentage"],
        ["fixed-amount", "Fixed Amount"],
        ["buy-x-get-y", "Buy X Get Y"],
    ]
);

export default {
    name: "CouponQuickViewSummary",
    props: {
        coupon: {
            type: Object,
            required: true
        },
    },
    data () {
        return {
            typeMap,
        };
    },
    computed: {

    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
